@import './userStyle/viewPage.scss';
@import './userStyle/commonStyle.scss';
@import './userStyle/settingsStyle.scss';
@import './userStyle/voidStyles.scss';
@import './userStyle/dashboardStyles.scss';
@import './userStyle/reportStyle.scss';
@import './userStyle/customerStyle.scss';
@import './userStyle/appDatePickerStyles.scss';
@import './userStyle/customFieldStyles.scss';
@import './userStyle/ChatStyle.scss';
@import './userStyle/tableHeaderFixedToTop.scss';
@import './userStyle/GovernementTransactions/pre-invoices.scss';
@import './userStyle/formLayer.scss';
@import './userStyle/TextEditor.scss';
@import './userStyle/CostCenterTrialBalanceReportTable.scss';
@import './userStyle/ReconscileStyles.scss';
@import './userStyle/SystemSettingsTable.scss';
@import './userStyle/SearchBox.scss';
@import './userStyle/VatLayoutReports.scss';
@import './userStyle/Dashboard.scss';
@import './userStyle/ToggleSwitch.scss';
@import './userStyle/DateRange.scss';
@import './theme/approval_stage';
@import './components/components';
@import './components//common-search';
@import './animation';
@import './components/asset-form';
@import './components/simplebar-height';
@import './components//fixed-asset-detail';
@import './components/app-date-time-picker';
@import './components/user-details';
@import './components/dynamic-proposal-editor';
@import './components/common_informatio_tile';
@import './components/loading_overlay';
@import './components/qr-code-builder';
@import './components/whatsapp-bot';
@import './components/meilisearch';
@import './components/multi_tenacy_login';
@import './components/customer_documents_table';
@import './components/_notification_setttings.scss';
@import './components/zatca_view_modal';
@import './components/table_data_info_section.scss';
@import './userStyle/Drilldown.scss';

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.navbar-vertical .navbar-nav .nav-item .nav-link {
  color: white !important;
}

//The focus time border has been removed. if which is needed please hide this code
:focus-visible {
  outline: none !important;
}

//Quick Links Dropdown style
.quick-links-dropdown {
  .dropdown-menu {
    left: -1.7rem !important;
  }
}

//Notification bar style
.Toastify__toast-container--top-center {
  border-radius: 10px !important;
  -webkit-border-radius: 10px !important;
  -moz-border-radius: 10px !important;
  -ms-border-radius: 10px !important;
  -o-border-radius: 10px !important;
  width: 30rem !important;

  .Toastify__toast {
    font-size: 0.85rem !important;
  }
}

// List Table style
.advance-table-styles {
  .hide-content {
    display: none !important;
    width: 100px !important;
  }

  tr:hover .hide-content {
    display: inline-block !important;
  }

  .button-styles-in-table {
    width: 10rem !important;
  }
}

//Month Selecting Dropdown
.month-selecting-dropdown {
  .css-8124o6-control:hover {
    box-shadow: none !important;
    outline: none !important;
  }

  .css-8124o6-control {
    box-shadow: none !important;
    outline: none !important;
  }

  .css-qrlw7w-control {
    box-shadow: none !important;
  }
}

.login-page-input-style {
  color: white !important;

  input.form-control {
    background-color: transparent !important;
    border: none !important;
    border-bottom: 1px solid white !important;
    box-shadow: none;
    color: white !important;
  }

  input.form-control::placeholder {
    color: rgb(1, 160, 111) !important;
    background-color: transparent !important;
  }

  input.form-control:focus {
    box-shadow: none !important;
    border-color: white !important;
    background-color: transparent !important;
  }

  input.form-control:hover {
    border-color: white !important;
    background-color: transparent !important;
  }
}

.login-form-button {
  .btn {
    border-radius: 5px !important;
    -webkit-border-radius: 5px !important;
    -moz-border-radius: 5px !important;
    -ms-border-radius: 5px !important;
    -o-border-radius: 5px !important;
    color: rgb(1, 160, 111) !important;
    box-shadow: none !important;
    border: 2px solid white !important;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .btn:hover {
    background-color: rgb(1, 160, 111) !important;
    color: white !important;
  }

  .btn:focus {
    background-color: white !important;
    color: rgb(1, 160, 111) !important;
  }
}

.require-data {
  color: #ff0000;
}

.require-data::after {
  content: '*';
  font-size: medium;
  color: #ff0000;
}

.view-page-dropdown {
  padding: 3px 8px !important;
  box-shadow: none !important;
  border: none !important;
}

::-webkit-scrollbar {
  display: none;
}

.btn-close {
  box-shadow: none !important;
  border: none !important;
}

.select {
  background-color: rgb(212, 242, 255);
}

input.form-control {
  border-radius: 0px !important;
  border: 1px solid #b5b5b5;
  font-size: 12px;
  line-height: 20px !important;
  font-weight: 400 !important;
}

input.form-control:focus {
  box-shadow: none !important;
  border-color: #2688ff !important;
}

input.form-control:hover {
  border-color: #2688ff !important;
}

input.form-control::placeholder {
  font-size: 12px;
  color: white !important;
}

textarea {
  border-radius: 0px !important;
  border: 1px solid #b5b5b5 !important;
  font-size: 12px !important;
  font-weight: 400 !important;
}

textarea::placeholder {
  color: rgb(140, 152, 165) !important;
}

textarea:focus {
  box-shadow: none !important;
  border-color: #2688ff !important;
}

textarea:hover {
  border-color: #2688ff !important;
}

.css-1s2u09g-control {
  border-radius: 0px !important;
  font-size: 12px !important;
  font-family: 'Inter', 'Source Sans Pro', Helvetica, Arial, sans-serif !important;
  min-height: 32px !important;
  height: 5px !important;
  border: 1px solid #b5b5b5 !important;
}

.css-319lph-ValueContainer {
  font-size: 12px;
  font-family: 'Inter', 'Source Sans Pro', Helvetica, Arial, sans-serif !important;
  text-transform: capitalize !important;
}

.css-6j8wv5-Input {
  margin: 0px !important;
  padding: 0px !important;
}

.css-rb1vna-control {
  min-height: 32px !important;
  height: 5px !important;
}

.css-1705uev-control {
  min-height: 32px !important;
  height: 5px !important;
}

.css-tlfecz-indicatorContainer {
  padding: 3px !important;
}

.css-1gtu0rj-indicatorContainer {
  padding: 3px !important;
}

.css-1hb7zxy-indicatorContainer {
  padding: 3px !important;
}

.css-1pahdxg-control {
  border-radius: 0px !important;
  -webkit-border-radius: 0px !important;
  -moz-border-radius: 0px !important;
  -ms-border-radius: 0px !important;
  -o-border-radius: 0px !important;
  min-height: 32px !important;
  height: 5px !important;
  border: 1px solid #2688ff !important;
  box-shadow: none !important;
}

.css-1jknvbe-menu {
  font-size: 12px;
  font-family: 'Inter', 'Source Sans Pro', Helvetica, Arial, sans-serif !important;
  text-transform: capitalize !important;
}

//Custom Input Wrapper multi select styles
.custom-input-wrapper {
  .css-13cymwt-control {
    height: auto !important;
  }

  .css-t3ipsp-control {
    height: auto !important;
  }
}

.discount-conversion-button {
  border-radius: 0px !important;
  -webkit-border-radius: 0px !important;
  -moz-border-radius: 0px !important;
  -ms-border-radius: 0px !important;
  -o-border-radius: 0px !important;
  background: white !important;
  padding-bottom: 0px !important;
  padding-top: 0px !important;
  padding-right: 4px !important;
  padding-left: 4px !important;
  border: 1px solid #b5b5b5 !important;
  box-shadow: none !important;
  position: relative !important;
  z-index: 0 !important;

  .btn:focus {
    border: 1px solid #b5b5b5 !important;
  }
}

.form-select {
  border-radius: 0px !important;
  -webkit-border-radius: 0px !important;
  -moz-border-radius: 0px !important;
  -ms-border-radius: 0px !important;
  -o-border-radius: 0px !important;
  border: 1px solid #b5b5b5 !important;
  font-size: 12px !important;
  line-height: 20px !important;
  font-weight: 400 !important;
}

.form-select:focus {
  box-shadow: none !important;
}

.form-select::placeholder {
  font-size: 10px !important;
}

.options {
  background-color: white !important;
  font-family: 'Inter', 'Source Sans Pro', Helvetica, Arial, sans-serif !important;
  font-size: 12px !important;
  border: 1px solid #b5b5b5 !important;
  border-radius: 0px !important;
  -webkit-border-radius: 0px !important;
  -moz-border-radius: 0px !important;
  -ms-border-radius: 0px !important;
  -o-border-radius: 0px !important;
  line-height: 25px !important;
}

.options:focus {
  border-color: #2688ff !important;
}

.options:hover {
  border-color: #2688ff !important;
}

.options::placeholder {
  color: rgb(140, 152, 165) !important;
}

td {
  vertical-align: middle;
}

.form-table-layout {
  thead {
    tr {
      th {
        font-weight: 600 !important;
        font-size: 12px !important;
      }
    }
  }
}

.table-label {
  font-family: 'Inter', 'Source Sans Pro', Helvetica, Arial, sans-serif !important;
  font-weight: 400 !important;
}

.table-header-size {
  font-size: 12px !important;
  padding: 8px !important;
  text-transform: uppercase !important;
}

button {
  border-radius: 0px !important;
  -webkit-border-radius: 0px !important;
  -moz-border-radius: 0px !important;
  -ms-border-radius: 0px !important;
  -o-border-radius: 0px !important;
}

.btn {
  border-radius: 0px !important;
  -webkit-border-radius: 0px !important;
  -moz-border-radius: 0px !important;
  -ms-border-radius: 0px !important;
  -o-border-radius: 0px !important;
}

.card {
  border-radius: 0px !important;
  -webkit-border-radius: 0px !important;
  -moz-border-radius: 0px !important;
  -ms-border-radius: 0px !important;
  -o-border-radius: 0px !important;
}

//for validation
.css-b62m3t-container {
  border-radius: 0px !important;
  -webkit-border-radius: 0px !important;
  -moz-border-radius: 0px !important;
  -ms-border-radius: 0px !important;
  -o-border-radius: 0px !important;
  border-color: #b5b5b5 !important;
}

.form-control.is-invalid {
  background-image: none !important;
  border-color: #f46161 !important;
}

.is-invalid .app-react-select__control {
  border-color: #f46161 !important;
  background-image: none !important;
}

.react-multi-select__control:focus {
  box-shadow: none !important;
  border-radius: 0px !important;
  border-color: #2688ff !important;
}

.react-multi-select__control {
  border-radius: 0px !important;
  border-color: #b5b5b5 !important;
}

.badge {
  border-radius: 0px !important;
  -webkit-border-radius: 0px !important;
  -moz-border-radius: 0px !important;
  -ms-border-radius: 0px !important;
  -o-border-radius: 0px !important;
}

.conversion-button:hover {
  color: black !important;
  background-color: rgb(228, 247, 228) !important;
}

.btn-group-vertical {
  outline: none !important;
  box-shadow: none !important;
}

.dropdown-item:hover {
  background-color: rgb(212, 210, 210) !important;
}

.dropdown-menu {
  padding: 0.5rem !important;
}

.btn-group.show .dropdown-toggle {
  box-shadow: none !important;
  outline: none !important;
  border: none !important;
  padding: 2px !important;
}

.btn-group:focus {
  box-shadow: none !important;
  outline: none !important;
  border: none !important;
  padding: 2px !important;
}

.transaction-card:hover {
  background-image: linear-gradient(
    rgba(0, 0, 0, 0.2),
    rgba(0, 0, 0, 0.2)
  ) !important;
}

.list-dropdown {
  .btn {
    padding: 2px !important;
  }

  .btn:focus {
    outline: none !important;
    box-shadow: none !important;
    border: none !important;
  }
}

.simplebar-style-with-pagination {
  height: 77vh !important;
  overflow-x: hidden !important;
  overflow-y: auto !important;
}

.simplebar-style-view-page {
  height: 79vh !important;
  overflow-x: hidden !important;
  overflow-y: auto !important;
}

.input-group-text {
  border-radius: 0px !important;
}

.mobile-view {
  flex-direction: row;
}

.Toastify__toast .Toastify__progress-bar {
  height: 1px !important;
}

.card-style {
  height: 91vh;
}

.cashier-screen-check {
  .form-check-label {
    margin: 0px !important;
    font-size: 10px !important;
    font-family: 'Inter', 'Source Sans Pro', Helvetica, Arial, sans-serif !important;
  }
}

.customer-contacts-image {
  height: 3rem !important;
  width: 3rem !important;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(188, 238, 188);
}

.customer-info-tab-content {
  h5 {
    font-size: 0.95rem;
  }

  p {
    font-size: 0.8rem;
  }
}

.activity-tree {
  li {
    border-left: 1px solid #00d27a;

    .icon-cover {
      position: relative;
      left: -11px;
      background-color: #fff;
      width: 25px;
      height: 25px;
      border-radius: 50%;

      svg {
        -webkit-transform: scaleX(-1);
        transform: scaleX(-1);
        position: relative;
        left: -1px;
      }
    }

    .card {
      margin-bottom: 1.5rem;
      max-width: 93% !important;

      .small {
        font-size: 0.65rem;
      }

      p {
        font-size: 0.75rem;
      }
    }

    :last-child {
      .card {
        margin-bottom: 0;
      }
    }
  }
}

.dropdown-button-style {
  font-size: 10px !important;
  padding: 7px !important;
  padding-left: 14px !important;
  padding-right: 14px !important;
}

.dropdown-button-print {
  font-size: 12px !important;
  padding: 0.25rem !important;
}

.finance-settings-label {
  .form-label {
    font-weight: 400 !important;
    padding: 6.7px !important;
  }
}

.list-item-letters {
  height: 2rem !important;
  width: 2rem !important;
  background-color: rgb(216, 216, 245);
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Georgia, 'Times New Roman', Times, serif !important;
  color: black;
}

.profile-image-wrapper {
  height: 20vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  .profile-image {
    height: 15vh;
    width: 15vh;
    border-radius: 50% !important;
    -webkit-border-radius: 50% !important;
    -moz-border-radius: 50% !important;
    -ms-border-radius: 50% !important;
    -o-border-radius: 50% !important;
  }
}

.role-style {
  border-radius: 5px !important;
  -webkit-border-radius: 5px !important;
  -moz-border-radius: 5px !important;
  -ms-border-radius: 5px !important;
  -o-border-radius: 5px !important;
}

//For display resolution 1680 * 1050
@media screen and (min-width: 1600px) and (max-width: 1680px) {
  .simplebar-style-with-pagination {
    height: 76.8vh !important;
  }

  .simplebar-style-view-page {
    height: 78.8vh !important;
  }
}

//For laptop screen
@media screen and (min-width: 1024px) and (max-width: 1440px) {
  input.form-control {
    line-height: 15px !important;
  }

  .css-1s2u09g-control {
    min-height: 27px !important;
    height: 4px !important;
  }

  .css-rb1vna-control {
    min-height: 27px !important;
    height: 4px !important;
  }

  .css-1705uev-control {
    min-height: 27px !important;
    height: 4px !important;
  }

  .css-tlfecz-indicatorContainer {
    padding: 2px !important;
  }

  .css-1gtu0rj-indicatorContainer {
    padding: 2px !important;
  }

  .css-1hb7zxy-indicatorContainer {
    padding: 2px !important;
  }

  .css-1pahdxg-control {
    min-height: 27px !important;
    height: 4px !important;
  }

  .css-1jknvbe-menu {
    font-size: 10px !important;
  }

  .form-select {
    line-height: 15px !important;
  }

  .form-table-layout {
    thead {
      tr {
        th {
          font-size: 10px !important;
        }
      }
    }
  }

  .table-header-size {
    font-size: 11px !important;
    padding: 4px !important;
  }

  .sales-view-page-heading {
    font-size: 17px !important;
  }
}

@media screen and (min-width: 886px) and (max-width: 1024px) {
  .sales-view-page-heading {
    font-size: 14px !important;
  }
}

@media screen and (min-width: 768px) and (max-width: 886px) {
  .sales-view-page-heading {
    font-size: 10px !important;
  }
}

@media screen and (min-width: 320px) and (max-width: 425px) {
  .simplebar-style {
    .form-wrapper {
      padding-left: 30px !important;
    }
  }

  .simplebar-style-with-pagination {
    height: 76vh !important;
  }

  .mobile-view {
    padding-top: 10px !important;
    padding-bottom: 8px !important;
    flex-direction: column !important;
    align-items: left !important;
  }

  .icon {
    height: 18px;
  }

  .profile-image-wrapper {
    align-items: center;
  }
}

.tile-link-row {
  margin: 0 -7.5px;
}

.tile-link {
  width: 100%;
  max-width: 180px;
  padding: 7.5px;
  transition: all 0.5s ease;

  &:hover {
    transform: scale(1.03);
  }

  .tiles-header {
    background-color: #325068d9 !important;
    padding: 10px !important;
    color: white !important;
    border-radius: 0px !important;
    -webkit-border-radius: 0px !important;
    -moz-border-radius: 0px !important;
    -ms-border-radius: 0px !important;
    -o-border-radius: 0px !important;
    text-overflow: clip !important;

    h6 {
      color: white !important;
    }
  }
}

.tile-link-header {
  .tiles-header {
    background-color: #325068d9 !important;
    padding: 10px !important;
    color: white !important;
    border-radius: 0px !important;
    -webkit-border-radius: 0px !important;
    -moz-border-radius: 0px !important;
    -ms-border-radius: 0px !important;
    -o-border-radius: 0px !important;
    text-overflow: clip !important;

    h6 {
      color: white !important;

      &:hover {
        cursor: pointer !important;
      }
    }
  }
}

.flex-fill-card-color {
  background-color: #eef2f8 !important;
  margin-top: 2rem;
  box-shadow: none;
}

.card-color {
  background-color: #eef2f8 !important;
}

$reportBreakPointStart992: 992px;
$reportBreakPointEnd1450: 1450px;

.report-item {
  .title {
    font-size: 1.35em !important;

    @media (min-width: $reportBreakPointStart992) and (max-width: $reportBreakPointEnd1450) {
      font-size: 1em !important;
    }
  }

  .report-title-icon {
    @media (min-width: $reportBreakPointStart992) and (max-width: $reportBreakPointEnd1450) {
      width: 1em !important;
    }
  }

  ul {
    padding-left: 2.5rem !important;

    li {
      @media (min-width: $reportBreakPointStart992) and (max-width: $reportBreakPointEnd1450) {
        font-size: 0.9em !important;
      }
    }
  }

  li.small {
    font-size: 1em;
  }
}

.show-on-table-row-hover {
  opacity: 0;
  transition: opacity 0.2s ease-in-out;

  .remove-all-buttons-margin-and-padding {
    * {
      padding: 0 !important;
      margin: 0 !important;
    }
  }
}

table {
  tr {
    &:hover {
      .show-on-table-row-hover {
        opacity: 1;
      }
    }
  }
}

.simplebar-custom-height-work-order {
  max-height: 58vh;
}

.simplebar-d-flex {
  .simplebar-content-wrapper,
  .simplebar-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }
}

.filter-bg-color {
  background-color: #f8f8f8;

  .filter-apply-button {
    height: 25px;
    width: 25px;
    font-size: 10px;
  }

  .filter-clear-button {
    height: 25px;
    width: 70px;
    font-size: 10px;
  }
}

.table {
  .table-row-seperation-tr {
    &:last-child {
      display: none;
    }

    .table-row-seperation-td {
      border: none;
      padding: 0.5rem;
      background-color: transparent;
      box-shadow: none;
    }
  }
}

.work-orders-camparison-report-container {
  .work-orders-camparison-report-line-item {
    min-width: 1000px;
    padding: 1.25rem;
    margin: 1.5rem;

    table {
      td {
        font-size: 0.78rem !important;
        padding: 0.5rem 0.7rem !important;
      }
    }

    .space-item {
      td {
        padding: 0.8rem 0 !important;
        border: none !important;
      }

      &:last-child {
        display: none;
      }
    }

    .bg-light-green {
      background-color: #ddf3e2 !important;
    }

    .bg-light-blue {
      background-color: #e0e3f7 !important;
    }

    .bg-light-red {
      background-color: #f7d8d6ad !important;
    }

    .bg-light-yellow {
      background-color: #e8ebc575 !important;
    }

    .bg-light-gray {
      background-color: #e1dedd !important;
    }
  }
}

//Modal style -  custom - after save
.custom-modal-position .modal-dialog {
  top: -100px !important;

  /* Adjust this value as needed */
  .after-save-modal-header {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .btn {
      padding: 0px !important;
      box-shadow: none !important;
      outline: none !important;
    }
  }
}

.password-expiry-screen {
  height: 100vh;

  .expiry-card {
    width: 440px;
    z-index: 1;
  }
}

.customer-advance-search-button-style {
  box-shadow: none !important;
  width: 38px;
  background-color: #edf2f9 !important;
  border: 1px solid #bababa !important;
  border-right: none !important;
}

.customer-advanced-filter-modal {
  .filter-input-width {
    max-width: 300;
  }

  .main-table-content {
    max-height: 700px;
    overflow-y: scroll;

    thead {
      background-color: rgb(237, 243, 248);

      .customer-name {
        max-width: 250px;
      }
    }
  }
}

.white-space-pre {
  white-space: pre;
}

.report-inner-table-scroll-handler {
  ::-webkit-scrollbar {
    display: initial !important;
    width: 0.7em;
    height: 0.7em;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #ccc;
  }

  ::-webkit-scrollbar-corner {
    background-color: #ccc;
  }
}

.thead-sticky {
  position: relative;

  thead {
    position: sticky;
    top: 0;
  }
}

.receieve-payment-action-button {
  box-shadow: rgba(0, 0, 0, 0.2) 0 3px 5px -1px,
    rgba(0, 0, 0, 0.14) 0 6px 10px 0, rgba(0, 0, 0, 0.12) 0 1px 18px 0 !important;
  font-size: 10px !important;
  padding: 6px 10px !important;
}

//form tab switching feature related styles start

.attachment-handler-btn-focus-style {
  &:focus {
    background-color: #2688ff;
    color: #fff;
  }
}

@mixin form-tab-switch-control-focus-style {
  border: 2px solid #2688ff !important;
  outline: none !important;
}

.app-react-select__control--is-focused,
.item-select-autocomplete__control--is-focused,
.react-date-picker--open {
  &:has(.form-tab-switch-control) {
    @include form-tab-switch-control-focus-style;
  }
}

.form-tab-switch-control {
  &.form-control,
  &.form-select,
  &.form-check-input {
    &:focus {
      @include form-tab-switch-control-focus-style;

      &.bg-primary {
        border-color: #000 !important;
      }
    }
  }
}

//form tab switching feature related styles end

.customer-shipping-address-card-view {
  &:hover {
    border: 1px solid #08c2ff !important;
    transform: scale(1.01) !important;
    transition: all 0.2s ease-in-out;
  }
}

//custom field popup custom value input style

.custom-field-popup-input-style {
  .css-13cymwt-control {
    height: 100% !important;
  }

  .css-t3ipsp-control {
    height: 100% !important;
  }
}

// select input flex-fill width scaling issue fix class
.form-select-flex-fill-width-fix {
  min-width: 0 !important;
}

.unpaid-invoices-dropdown {
  &:focus {
    border: 2px dashed #2684ff !important;
  }
}

.enable-horizotal-scroling-for-advanced-table {
  overflow-x: scroll !important;
}

//for appointment status change dropdown
.app-dropdown-appointment {
  button {
    padding: 0px;
    font-size: 13px;
    width: 130px;
    background-color: #ffffff;
    outline: none !important;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
      rgba(0, 0, 0, 0.08) 0px 0px 0px 1px !important;
  }
}

.supplier-allocation-filter-input {
  width: 380px !important;
}

.supplier-allocation-filter-input-btn {
  margin-top: 5px !important;
}

@media screen and (max-width: 768px) {
  .supplier-allocation-filter-input {
    width: 100% !important;
  }

  .supplier-allocation-filter-input-btn {
    width: 100% !important;
    margin-top: 0px !important;
  }

  .supplier-allocation-filter-input-date {
    width: 100% !important;

    .form-label {
      width: 110px !important;
    }
  }
}

// react-date-picker__calendar core styles overwritten
.react-date-picker__calendar {
  z-index: 2 !important;
}

// react-date-picker__calendar core styles overwritten end

//navbar bottom crm link style
.navbar-vertical .nav-bar-crm-link {
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: #000;
  cursor: pointer;

  img {
    width: 90px;
  }
}
//sub category filter tree node hover card style
.sub-category-tree-node {
  .hover-card:hover {
    background-color: #fee2e2;
  }
}

//custom table cell loader style
.custom-table-cell-loader:before {
  visibility: visible;
  display: table-caption;
  content: ' ';
  width: 100%;
  height: 177px;
  background-image: linear-gradient(rgba(235, 235, 235, 1) 1px, transparent 0),
    linear-gradient(90deg, rgba(235, 235, 235, 1) 1px, transparent 0),
    linear-gradient(
      90deg,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.5) 15%,
      rgba(255, 255, 255, 0) 30%
    ),
    linear-gradient(#edf2f5 35px, transparent 0);

  background-repeat: repeat;

  background-size: 1px 35px, calc(100% * 0.1666666666) 1px, 30% 100%, 2px 70px;

  background-position: 0 0, 0 0, 0 0, 0 0;

  animation: shine 0.5s infinite;
}

@keyframes shine {
  to {
    background-position: 0 0, 0 0, 40% 0, 0 0;
  }
}
